import {createContext, useContext } from "react"
import {ValidationErrors} from "../generated/graphql";

export interface SnackbarContext {
    message: SnackbarContextMessage | null,
    setMessage: (message: SnackbarContextMessage['message'], severity?: SnackbarContextSeverity) => void,
    removeMessage: () => void,

}
export type SnackbarContextSeverity = 'error' | 'warning' | 'success' | 'info'

export interface SnackbarContextMessage { message: string | Error | ValidationErrors, severity?: SnackbarContextSeverity }


export const SnackbarContext = createContext<SnackbarContext>({
    message: null,
    setMessage: (_message: SnackbarContextMessage['message'], _severity?: SnackbarContextSeverity) => {},
    removeMessage: () => {},
})

export const useSnackbar = () => {
    return useContext(SnackbarContext)
}