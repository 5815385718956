
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AuthenticatorResult": [
      "Authenticator",
      "ValidationErrors"
    ],
    "CardResult": [
      "Card",
      "ValidationErrors"
    ],
    "CardListResult": [
      "CardList",
      "ValidationErrors"
    ],
    "ProjectResult": [
      "Project",
      "ValidationErrors"
    ],
    "FileResult": [
      "File",
      "ValidationErrors"
    ],
    "LabelResult": [
      "Label",
      "ValidationErrors"
    ]
  }
};
      export default result;
    