import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  name: Scalars['String'];
  projects: ProjectList;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  labels: Array<Label>;
  cards: CardList;
};

export type ProjectList = {
  __typename?: 'ProjectList';
  list: Array<Project>;
};

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  number: Scalars['Int'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label?: Maybe<Label>;
};


export type CardList = {
  __typename?: 'CardList';
  list: Array<Card>;
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['ID'];
  name: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  changeWarning?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  cardByCode?: Maybe<Card>;
  card: Card;
  project: Project;
};


export type QueryCardByCodeArgs = {
  code: Scalars['String'];
};


export type QueryCardArgs = {
  id: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountLogin: AuthenticatorResult;
  accountLogout: Scalars['Boolean'];
  accountCreate: AuthenticatorResult;
  cardSetLabel: CardResult;
  cardCreate: CardListResult;
  projectCreate: ProjectResult;
  projectDownloadProjectCards: FileResult;
  labelCreate: LabelResult;
  setDefaultLabel: LabelResult;
};


export type MutationAccountLoginArgs = {
  AccountLoginArgs: AccountLoginArgs;
};


export type MutationAccountCreateArgs = {
  AccountCreateArgs: AccountCreateArgs;
};


export type MutationCardSetLabelArgs = {
  CardSetLabelArgs: CardSetLabelArgs;
};


export type MutationCardCreateArgs = {
  CardCreateArgs: CardCreateArgs;
};


export type MutationProjectCreateArgs = {
  ProjectCreateArgs: ProjectCreateArgs;
};


export type MutationProjectDownloadProjectCardsArgs = {
  id: Scalars['ID'];
};


export type MutationLabelCreateArgs = {
  LabelCreateArgs: LabelCreateArgs;
};


export type MutationSetDefaultLabelArgs = {
  LabelSetDefaultArgs: LabelSetDefaultArgs;
};

export type AuthenticatorResult = Authenticator | ValidationErrors;

export type Authenticator = {
  __typename?: 'Authenticator';
  token: Scalars['String'];
  account: Account;
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors: Array<ValidationError>;
};

export type AccountLoginArgs = {
  identifier: Scalars['String'];
  password: Scalars['String'];
};

export type AccountCreateArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
};

export type CardResult = Card | ValidationErrors;

export type CardSetLabelArgs = {
  card: Scalars['ID'];
  label: Scalars['ID'];
};

export type CardListResult = CardList | ValidationErrors;

export type CardCreateArgs = {
  project: Scalars['ID'];
  count: Scalars['Int'];
};

export type ProjectResult = Project | ValidationErrors;

export type ProjectCreateArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type FileResult = File | ValidationErrors;

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type LabelResult = Label | ValidationErrors;

export type LabelCreateArgs = {
  name: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  sort: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
  project: Scalars['ID'];
};

export type LabelSetDefaultArgs = {
  label: Scalars['ID'];
};

export type CardFragment = (
  { __typename?: 'Card' }
  & Pick<Card, 'id' | 'number' | 'code' | 'updatedAt' | 'createdAt'>
  & { label?: Maybe<(
    { __typename?: 'Label' }
    & LabelFragment
  )> }
);

export type ValidationErrorsFragment = (
  { __typename?: 'ValidationErrors' }
  & { errors: Array<(
    { __typename?: 'ValidationError' }
    & Pick<ValidationError, 'code' | 'message'>
  )> }
);

export type LabelFragment = (
  { __typename?: 'Label' }
  & Pick<Label, 'id' | 'color' | 'description' | 'title' | 'name'>
);

export type CardByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type CardByCodeQuery = (
  { __typename?: 'Query' }
  & { cardByCode?: Maybe<(
    { __typename?: 'Card' }
    & CardFragment
  )> }
);

export const LabelFragmentDoc = gql`
    fragment Label on Label {
  id
  color
  description
  title
  name
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on Card {
  id
  number
  code
  label {
    ...Label
  }
  updatedAt
  createdAt
}
    ${LabelFragmentDoc}`;
export const ValidationErrorsFragmentDoc = gql`
    fragment ValidationErrors on ValidationErrors {
  errors {
    code
    message
  }
}
    `;
export const CardByCodeDocument = gql`
    query cardByCode($code: String!) {
  cardByCode(code: $code) {
    ...Card
  }
}
    ${CardFragmentDoc}`;
export function useCardByCodeQuery(baseOptions: Apollo.QueryHookOptions<CardByCodeQuery, CardByCodeQueryVariables>) {
        return Apollo.useQuery<CardByCodeQuery, CardByCodeQueryVariables>(CardByCodeDocument, baseOptions);
      }
export function useCardByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardByCodeQuery, CardByCodeQueryVariables>) {
          return Apollo.useLazyQuery<CardByCodeQuery, CardByCodeQueryVariables>(CardByCodeDocument, baseOptions);
        }
export type CardByCodeQueryHookResult = ReturnType<typeof useCardByCodeQuery>;
export type CardByCodeLazyQueryHookResult = ReturnType<typeof useCardByCodeLazyQuery>;
export type CardByCodeQueryResult = Apollo.QueryResult<CardByCodeQuery, CardByCodeQueryVariables>;