import React, {useEffect, useState } from "react";
import {Snackbar} from "../components/SnackBar";

import {ValidationErrors} from "../generated/graphql";
import {SnackbarContext, useSnackbar} from "../context/SnackbarContext";

const getMessageText = (context: SnackbarContext['message']): string => {
    if (!context) {
        return ''
    }
    const { message } = context
    if (!message) {
        return ''
    }
    if (typeof message === 'string') {
        return message
    }
    if (message instanceof Error) {
        return message.message
    }
    const apolloError = (message as ValidationErrors).errors
    if (Array.isArray(apolloError)) {
        return apolloError.map(e => e.message).join(' | ')
    }
    return ''
}

export const MessageBox: React.FC = () => {
    const { message, removeMessage } = useSnackbar()
    const [showSnackbar, setShowSnackbar] = useState<boolean>(Boolean(message))

    useEffect(() => {
        if (message) {
            setShowSnackbar(true)
        }
    }, [message])


    return ( message ? <Snackbar open={showSnackbar} onClose={() => {
        setShowSnackbar(false)
        removeMessage()
    }} severity={message.severity}>{getMessageText(message)}</Snackbar> : null)
}