import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import NotFound from "./NotFound";
import CardDetails from "./Card";
import {ProvideSnackbar} from "./context/SnackbarProvider";


export default function Init() {
    return (

            <Router>
                <ProvideSnackbar>
                <div>
                    <Switch>
                        <Route path="/status/:code">
                            <CardDetails />
                        </Route>
                        <Route exact path="/">
                            <CardDetails />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
                </ProvideSnackbar>
            </Router>

    );
}