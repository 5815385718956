import React, { useState, useCallback } from 'react'
import {SnackbarContext, SnackbarContextMessage, SnackbarContextSeverity } from './SnackbarContext';

const severities: Array<SnackbarContextSeverity> = ['error','warning', 'success', 'info']

export const ProvideSnackbar: React.FC = ({ children }) => {
    const [message, setMessage] = useState< SnackbarContext['message'] | null>(null)

    const removeError = () => setMessage(null)
    const addError = (message: SnackbarContextMessage['message'], severity?: SnackbarContextSeverity) => {
        setMessage({ message, severity })
    }

    const ctx: SnackbarContext = {
        message,
        setMessage: useCallback((message, status = 'error') => addError(message, severities.includes(status) ? status : 'error'), []),
        removeMessage: useCallback(() => removeError(), []),
    }

    return (
        <SnackbarContext.Provider value={ctx}>
            {children}
        </SnackbarContext.Provider>
    )
}
