import {CssBaseline} from "@material-ui/core";
import React from "react";
import {MessageBox} from "./MessageBox";

export const Layout: React.FC = ({ children }) => {
    return (
        <>
        <CssBaseline />
        {children}
        <MessageBox />
        </>
        )
}