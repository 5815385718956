import React from 'react';
import Snack from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export const Snackbar: React.FC<{ open: boolean, onClose: () => void, severity?: 'error'| 'warning' | 'info' | 'success' }> = (props) => {
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.onClose()
    };

    return (
            <Snack open={props.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.severity}>
                    {props.children}
                </Alert>
            </Snack>
    );
}