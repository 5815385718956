import React, {useState} from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import CardLayout from '@material-ui/core/Card'
import IconClear from '@material-ui/icons/Clear'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Card, useCardByCodeLazyQuery} from './generated/graphql'
import {Page} from './layout/Page'
import {useSnackbar} from "./context/SnackbarContext";
import {CardContent, IconButton, InputAdornment, Typography} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    depositContext: {
        flex: 1,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    card: {
        width: '100%',
    },
    bullet: {
        display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },

    description: {
        fontSize: 14,
        marginBottom: 12,
    },
}));

export default function CardDetails() {
    const classes = useStyles();
    const [card, setCard] = useState<Card>()
    const [code, setCode] = useState<string>('')
    const { setMessage } = useSnackbar()


    const [cardQuery, result] = useCardByCodeLazyQuery({
        fetchPolicy: 'network-only',
        pollInterval: 60000,
        onCompleted: ({ cardByCode }) => {
            if (cardByCode) {
                setCard(cardByCode)
                setCode(cardByCode.code)
                return
            }
            setMessage(`Leider konnten wir für den Code keine Ergebnis finden. ${code ? `(Code: ${code})` : ''}`)
        },
        onError: setMessage
        },
    )

        return (
        <Page title="Testergebnis">
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="identifier"
                        label="Code eingeben"
                        name="code"
                        autoFocus
                        value={code}
                        onChange={e => {
                            e.preventDefault();
                            setCode(e.target.value)
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="Neuen Code eingebn"
                                    onClick={() => setCode('')}

                                >
                                    <IconClear />
                                </IconButton>
                            </InputAdornment>
                        }}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={(e) => {
                            e.preventDefault();
                            cardQuery({
                                variables: { code }
                            })
                        }}
                        disabled={code.length === 0}
                    >
                        Status Abrufen
                    </Button>
                </form>

            {result?.loading ? <CircularProgress /> : null}

            { card ? <CardLayout className={classes.card}>
                <CardContent>
                    <Box m={2} >
                        <Typography variant="subtitle1" gutterBottom>Testcode:   <Chip label={card.code} /> </Typography>
                    </Box>
                    <Box m={2} >
                    <Typography variant="subtitle1" gutterBottom>
                        Ergebnis: {card.label ? <Chip label={card.label.name} style={card.label.color ? { backgroundColor: card.label.color } : undefined}/> : null}
                    </Typography>
                    </Box>
                    <Divider />
                    {card.label ? <Alert style={card.label.color ? { color: 'white', backgroundColor: card.label.color } : undefined}>
                        {card.label?.description}
                    </Alert> : null}
                </CardContent>
            </CardLayout> : null }
        </Page>
    );
}