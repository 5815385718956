import { ApolloClient, InMemoryCache } from '@apollo/client'
import fragments from "../generated/fragments";

// const uri = 'https://localhost:8000/api/v1/'
// const uri = 'https://4m6xaj2v78.execute-api.eu-central-1.amazonaws.com/prod/api/v1/'
const uri = 'https://api.testergebnis.at/api/v1/'

export const client = new ApolloClient({
  uri,
  cache: new InMemoryCache({
    possibleTypes: fragments.possibleTypes,
  }),
})

