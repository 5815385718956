import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {ApolloProvider} from '@apollo/client'
import {client} from './api/clients'
import Init from './Init'

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Init />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
