import {Container, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {Layout} from "./Layout";
import {MessageBox} from "./MessageBox";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export const Page: React.FC<{ title?: string }> = ({ title, children }) => {
    const classes = useStyles()

    return (
        <Layout>
        <Container className={classes.paper}  component="main" maxWidth="xs">
            {title && <Typography component="h1" variant="h5">{title}</Typography>}
            {children}
            <MessageBox />
       </Container>
        </Layout>
        );
}